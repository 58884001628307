import React, {useState, useEffect} from 'react';
import {TextAreaField, Button, Table, TableCell, TableBody, TableHead, TableRow, Flex} from '@aws-amplify/ui-react';
import {API} from "aws-amplify";

const Enrollments = ({selectedClass}) => {
    const [emailList, setEmailList] = useState('');  // For the TextAreaField
    const [enrolledEmails, setEnrolledEmails] = useState([]);  // Placeholder for enrolled emails

    const fetchEnrollments = async (classId) => {
        // Adjust 'myAPI' to match the name of your API as specified in your Amplify setup
        const response = await API.get('cloakapi', `/enrollment/${classId}`);
        console.log("Received response from /enrollment:", response);
        // setEnrolledEmails(fetchEnrollments(selectedClass.id));
        setEnrolledEmails(response.enrollments || []);
    }

// Fetch enrolled emails when selectedClass changes
    useEffect(() => {
        if (selectedClass) {
            fetchEnrollments(selectedClass.classId);
        }
    }, [selectedClass]);

    const handleBatchAdd = () => {
        // Split the emailList by comma and newline, then trim whitespace
        const emailsToAdd = emailList.split(/[,\n]/).map(email => email.trim().toLowerCase());
        // Remove any existing emails from the list
        const emailsToAddUnique = emailsToAdd.filter(email => !enrolledEmails.includes(email));

        // Make an API post request with the email list as a json object in the body
        const postEnrollments = async (classId, emails) => {
            const response = await API.post('cloakapi', `/enrollment/${classId}`, {
                body: {
                    emails: emailsToAddUnique
                }
            });
            console.log("Received response from /enrollment:", response);
        }

        postEnrollments(selectedClass.classId, emailsToAddUnique).then(() => {
            // add unique emails t the current list of enrolled emails
            setEnrolledEmails([...enrolledEmails, ...emailsToAddUnique]);
        }).then(() => {
            setEmailList('');
        });

        // Call your API to add these emails to the enrollments for selectedClassId
        // Update the state or re-fetch the enrollments after successfully adding
    };

    // The API pattern for removing an enrollment is the same as bulk add, except you use a DELETE method instead of POST
    const handleRemove = (email) => {
        const removeEnrollment = async (classId, email) => {
            const response = await API.del('cloakapi', `/enrollment/${classId}`, {
                body: {
                    emails: [email]
                }
            });
            console.log("Received response from /enrollment:", response);
        }

        removeEnrollment(selectedClass.classId, email).then(() => {
            // remove the email from the current list of enrolled emails
            setEnrolledEmails(enrolledEmails.filter(enrolledEmail => enrolledEmail !== email));

        });
    };

    return (
        <div>
            <h2>Manage Enrollments for: {selectedClass.title} [{selectedClass.classId}]</h2>
            <h3>Enrolled: {enrolledEmails.length}</h3>
            <Flex direction="column" gap="1rem">

                <TextAreaField
                    label="Enter emails separated by comma or newline"
                    name="emailList"
                    value={emailList}
                    onChange={(e) => setEmailList(e.target.value)}
                />
                <Flex direction="row-reverse" gap="1rem">
                    <Button onClick={handleBatchAdd}>Batch Add</Button>
                </Flex>

                <Table variation={"striped"}>
                    <TableHead>
                        <TableRow>
                            <TableCell as="th">Email</TableCell>
                            <TableCell as="th">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {enrolledEmails.map((email, index) => (
                            <TableRow key={index}>
                                <TableCell>{email}</TableCell>
                                <TableCell><Button onClick={() => handleRemove(email)}>Remove</Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Flex>
        </div>
    );
};

export default Enrollments;
