import React, {useEffect, useState} from 'react';
import {API} from 'aws-amplify';
import {
    Grid, Card, View, Button, Label, Input, Flex, Alert, Fieldset, CheckboxField, Link, Placeholder, Loader, Badge,
} from '@aws-amplify/ui-react';
import LabDefinitions from "./LabDefinitions";
import Enrollments from "./Enrollments";

const ClassAdministration = () => {
    const [classes, setClasses] = useState([]);
    const [selectedClass, setSelectedClass] = useState(null);
    const [classId, setClassId] = useState('');
    const [title, setTitle] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [createNew, setCreateNew] = useState(false);
    const [selectedLabIds, setSelectedLabIds] = useState([]);
    const [classListIsLoading, setClassListIsLoading] = useState(false);

    const fetchClasses = async () => {
        try {
            // fetch class list from cloakInstructorAPI rest api using AWS Amplify.
            setClassListIsLoading(true);
            const classData = await API.get('cloakapi', '/classes');
            console.log('classData: ', classData);
            setClassListIsLoading(false);
            setClasses(classData.classes || []);
        } catch (error) {
            setClassListIsLoading(false);
            console.log('error on fetching classes: ', error);
        }

    }

    useEffect(() => {

        fetchClasses();

        if (selectedClass) {
            setClassId(selectedClass.classId);
            setTitle(selectedClass.title);
            setStartDate(selectedClass.startDate);
            setEndDate(selectedClass.endDate);
            setSelectedLabIds(selectedClass.labIds);
        }

    }, [selectedClass]);  // The empty dependency array ensures this useEffect runs only once on component mount

    const handleSelectClass = (classItem) => {
        setCreateNew(false);
        if (!classItem.labIds) {
            classItem.labIds = [];
        }
        setSelectedClass(classItem);
        setClassId(classItem.classId);
        setTitle(classItem.title);
        setStartDate(classItem.startDate);
        setEndDate(classItem.endDate);
        setSelectedLabIds(classItem.labIds);
    };

    function clearDetails() {
        setSelectedClass(null);
        setClassId('');
        setTitle('');
        setStartDate('');
        setEndDate('');
        setCreateNew(false);
        setSelectedLabIds([]);
    }

    const handleSave = async () => {
        // Call your API to save changes. Use the state variables as the new values.
        const updatedClass = {
            classId: classId || selectedClass.classId,
            title,
            startDate,
            endDate,
            labIds: selectedLabIds,
        };
        // Call your API to save changes.
        if (createNew) {
            const createClass = async (classData) => {
                const response = await API.post('cloakapi', `/class/${classId}`, {
                    body: classData,
                });
                console.log('Received response from /class: ', response);
            }
            createClass(updatedClass).then(() => {
                // Update the classes list
                fetchClasses();
                // Clear the details form
                clearDetails();
            });
        } else {
            const saveClass = async (classId, classData) => {
                const response = await API.post('cloakapi', `/class/${classId}`, {
                    body: classData,
                });
                console.log('Received response from /class: ', response);
            }

            saveClass(selectedClass.classId, updatedClass).then(() => {
                setClassId(updatedClass.classId);
                setTitle(updatedClass.title);
                setStartDate(updatedClass.startDate);
                setEndDate(updatedClass.endDate);
                setSelectedLabIds(updatedClass.labIds);
            });
        }
    };

    const handleCancel = () => {
        // Revert state variables to the values in selectedClass.
        if(createNew) {
            setSelectedClass(null);
            setClassId('');
            setTitle('');
            setStartDate('');
            setEndDate('');
            setCreateNew(false);
            return;
        }

        else if (!selectedClass) return;
        setClassId(selectedClass.classId);
        setTitle(selectedClass.title);
        setStartDate(selectedClass.startDate);
        setEndDate(selectedClass.endDate);
        setCreateNew(false);
        setSelectedLabIds(selectedClass.labIds);
    };

    const needsSave = () => {
        // check if labs have changed
        let labsChanged = false;

        if (selectedClass && !createNew) {
            const { labIds = [] } = selectedClass;
            const safeSelectedLabIds = selectedLabIds || [];
            labsChanged = safeSelectedLabIds.sort().join(',') !== labIds.sort().join(',');
        }
        return createNew || (selectedClass &&
                (labsChanged ||
                classId !== selectedClass.classId ||
                title !== selectedClass.title ||
                startDate !== selectedClass.startDate ||
                endDate !== selectedClass.endDate));
    }

    const handleCreateNew = () => {
        setCreateNew(true);
        setSelectedClass(null);
        setClassId('');
        setTitle('');
        setStartDate('');
        setEndDate('');
        // enable all labs by default
        setSelectedLabIds(Object.keys(LabDefinitions));
    }

    const handleDelete = async () => {
        // Call your API to delete the class.
        const deleteClass = async (classId) => {
            const response = await API.del('cloakapi', `/class/${classId}`);
            console.log('Received response from /class: ', response);
        }
        deleteClass(selectedClass.classId).then(() => {
            // Update the classes list
            fetchClasses();
            // Clear the details form
            clearDetails();
        });
    }

    // function to check if the ID is unique and not blank
    const checkId = (id) => {
        if (id === '') return false;
        return !classes.find((classItem) => classItem.classId === id);
    }

    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        if (e.target.checked) {
            setSelectedLabIds([...selectedLabIds, value]);
        } else {
            setSelectedLabIds(selectedLabIds.filter((id) => id !== value));
        }
    };


    return (
        <div>
            <h2>Class Administration</h2>
            <Grid
                columnGap="0.5rem"
                rowGap="0.5rem"
                templateColumns="1fr 3fr"
                templateRows="1fr"
            >
                <Card columnStart={1}
                      columnEnd={2}>
                        <h3 style={{textAlign: 'left'}}>Class List</h3>
                    <Flex direction={"column"} gap={"0.5rem"}>
                        {classes.map((classItem) => (
                            <Button isFullWidth={true} key={classItem.classId}
                                    onClick={() => handleSelectClass(classItem)}>
                                <span style={{ marginRight: '8px' }}>{classItem.title}</span>
                                {classItem.classSize ? (<Badge>{classItem.classSize}</Badge>) : ''}
                            </Button>
                        ))}
                        <Placeholder size="large" isLoaded={!classListIsLoading}/>
                        {!classListIsLoading && (
                        <Button variation="primary" colorTheme="overlay"
                                isFullWidth={true} onClick={handleCreateNew}>Create New</Button>)}
                    </Flex>
                </Card>
                <Card columnStart={2}
                      columnEnd={-1}>
                    <View style={{textAlign: 'left'}}>
                        {createNew ? (<h3>Create New Class</h3>) : (<h3>Class Details{selectedClass?`: ${selectedClass.title}`:''}</h3>)}
                        {(selectedClass || createNew) ? (
                            <Flex direction={"column"} gap={"1.5rem"}>
                                <Flex direction="row" gap="1rem">
                                    <View>
                                        <Label htmlFor="class_id">ID:</Label>
                                        <Input id="class_id"
                                               name="class_id"
                                               value={classId || ''}
                                               hasError={!checkId(classId)}
                                               isRequired={true}
                                               onChange={(e) => setClassId(e.target.value)}
                                                  disabled={!createNew}
                                        />
                                    </View>
                                    <View>
                                        <Label htmlFor="title">Title:</Label>
                                        <Input id="title" name="title"
                                               value={title || ''}
                                               isRequired={true}
                                               onChange={(e) => setTitle(e.target.value)}/>
                                    </View>
                                    <View>
                                        <Label htmlFor="startDate">Start Date:</Label>
                                        <Input id="startDate" name="startDate" placeholder="yyyy-mm-dd"
                                               value={startDate || ''}
                                               onChange={(e) => setStartDate(e.target.value)}/>
                                    </View>
                                    <View>
                                        <Label htmlFor="endDate">End Date:</Label>
                                        <Input id="endDate" name="endDate" placeholder="yyyy-mm-dd"
                                               value={endDate || ''}
                                               onChange={(e) => setEndDate(e.target.value)}/>
                                    </View>
                                </Flex>
                                <Flex direction="row" gap="1rem">
                                    <Fieldset legend="Labs">
                                        {Object.keys(LabDefinitions).map((labId) => (
                                            <CheckboxField
                                                key={labId}
                                                name={labId}
                                                label={LabDefinitions[labId].title}
                                                value={labId}
                                                checked={selectedLabIds.includes(labId)}
                                                onChange={(e) => handleCheckboxChange(e)}
                                            />
                                        ))}
                                    </Fieldset>

                                </Flex>
                                <Flex direction="row-reverse" gap="1rem">
                                    {!createNew && <Button onClick={handleDelete}>Delete</Button>}
                                    <Button onClick={handleCancel} isDisabled={!needsSave()}>Cancel</Button>
                                    {createNew ? (<Button onClick={handleSave} isDisabled={!checkId(classId)}>Create</Button>) :
                                    <Button onClick={handleSave} isDisabled={!needsSave()}>Save</Button>}
                                </Flex>

                                { createNew ? (
                                    <Alert>Please create your class before you manage enrollment information.</Alert>
                                )
                                : <Enrollments selectedClass={selectedClass}/> }
                            </Flex>
                        ) : (
                            <Alert
                                variation={"info"}
                                heading="No class selected">
                                Please select a class from the list on the left or <Link onClick={handleCreateNew}>create a new one</Link>.
                            </Alert>
                        )}
                    </View>
                </Card>
            </Grid>
        </div>
    );
};

export default ClassAdministration;
