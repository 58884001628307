// LabDefinitions.js

const LabDefinitions = {
    'dojo-basic': {
        title: 'Dojo Basic'
    },
    'juice-shop': {
        title: 'Juice Shop'
    },
    'dvwa': {
        title: 'Damn Vulnerable Web App'
    },
    'wayfarer': {
        title: 'Wayfarer'
    },
    'cors.musashi-js': {
        title: 'Musashi'
    },
};

export default LabDefinitions;
