import './App.css';
import {Auth, Hub} from 'aws-amplify';
import {
    Authenticator,
    useTheme,
    View,
    Image,
    Text,
    Button,
    Grid,
    Card,
    Loader,
    Tabs,
    TabItem
} from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css';
import React from "react";
import { useState, useEffect } from 'react';
import LabURLs from "./LabURLs";
import ClassAdministration from "./ClassAdministration";


function App() {
    const [givenName, setGivenName] = useState(null);
    const [isInstructor, setIsInstructor] = useState(false);
    const [activeTab, setActiveTab] = useState('labUrls');

    const listener = (data) => {
        switch (data?.payload?.event) {
            case 'signIn':
                setGivenName(data.payload.data.attributes.given_name);
                break;
case 'signOut':
                setGivenName(null);
                break;
            default:
                break;
        }
    }

    Hub.listen('auth', listener);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                setGivenName(user.attributes.given_name);

                // Check if user is in the "Instructors" cognito group
                if (user.signInUserSession.idToken.payload['cognito:groups'] &&
                    user.signInUserSession.accessToken.payload['cognito:groups']?.includes('Instructors')) {
                    setIsInstructor(true);
                }
            })
            .catch(() => console.log("Not signed in"));
    });

    const signOut = async () => {
        try {
            await Auth.signOut();
            window.location.reload();
        } catch (error) {
            console.log('Error signing out:', error);
        }
    };

    const PageFooter = () => {
        const {tokens} = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Text color={tokens.colors.neutral[80]}>
                    Secure Ideas, LLC
                </Text>
            </View>
        );
    }

    const components = {
        Header() {
            const {tokens} = useTheme();

            return (
                <View textAlign="center" padding={tokens.space.large}>
                    <Image
                        height='135px'
                        alt="Secure Ideas logo"
                        src="https://www.secureideas.com/hubfs/si_logo_lock_full_color_tsp.svg"
                    />
                    <h2>PENLABS</h2>
                </View>
            );
        },

        Footer() {
            return PageFooter();
        }
    }


    const formFields = {
        signIn: {
            username: {
                placeholder: 'Enter Your Email Here',
                isRequired: true,
                label: 'Email:'
            },
        },
        signUp: {
            given_name: {
                placeholder: 'Enter Your First Name Here',
                isRequired: true,
                label: 'First Name'
            },
            family_name: {
                placeholder: 'Enter Your Last Name Here',
                isRequired: true,
                label: 'Last Name'
            }
        }
    }

    // Sign out button
    const SignOutButton = () => {
        return (
            <Button variation={"primary"}
            colorTheme={"error"}
                    onClick={signOut}>Sign Out</Button>
        );
    }

    return (
        <Authenticator formFields={formFields} components={components}>
            <div className="App">
                <Grid
                    templateColumns="1fr"
                    templateRows="1fr auto 1fr"
                    gap="0"
                    >
                    <Grid
                        templateColumns="1fr 1fr 1fr"
                        templateRows="1fr"
                        gap="0"
                    >
                        <header className="App-header">
                            <h2>Hello, { givenName || <Loader />}!</h2>
                            {isInstructor && (
                                <Tabs>
                                    <TabItem title={"My Lab URLs"} onClick={() => setActiveTab('labUrls')}/>
                                    <TabItem title={"Class Administration"} onClick={() => setActiveTab('classAdmin')}/>
                                </Tabs>
                                )}
                        </header>
                        <Card>
                            <h1>PENLABS</h1>
                        </Card>
                        <Card>
                            <SignOutButton />
                        </Card>

                    </Grid>
                    <Card
                    padding={"0"}
                    >
                        {activeTab === 'labUrls' && <LabURLs />}
                        {activeTab === 'classAdmin' && <ClassAdministration />}
                    </Card>

                </Grid>
                <PageFooter/>
            </div>
        </Authenticator>
    );
}

export default App;

//export default withAuthenticator(App);
