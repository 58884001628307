import React, {useEffect, useState} from 'react';
import {API} from 'aws-amplify';
import {Card, Flex, Grid, Heading, Label, Loader, Placeholder, TextField, View} from "@aws-amplify/ui-react";


const LabURLs = () => {
    const [classes, setClasses] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    // calculate the grid rows repeat string given a lab array
    const calculateGridRows = (labArray) => {
        return "repeat(" + labArray.length + ", 1fr)";
    };


    useEffect(() => {

        const fetchData = async () => {
            try {
                setError(null);

                // Adjust 'myAPI' to match the name of your API as specified in your Amplify setup
                const response = await API.get('cloakapi', '/poll');
                setClasses(response.classes || []);
                setLoading(false);
                // Log the response for inspection
                console.log("Received response from /poll:", response);

            } catch (error) {
                setError(error);
                setLoading(false);
                console.error("An error occurred while fetching data:", error);
            }
        };

        fetchData();
        // Set up polling every 30 seconds
        const interval = setInterval(() => {
            fetchData();
        }, 600000);

        return () => clearInterval(interval);

    }, []);  // The empty dependency array means this useEffect runs once when the component mounts

    const copyToClipboard = (url) => {
        navigator.clipboard.writeText(url);
    };

    return (
        <div>
            {isLoading && <Loader/>}
            {error && <p>An error has occurred, please notify your instructor.</p>}
            {!isLoading && !error && classes.length === 0 && (
                <p>You have not been enrolled in any classes.</p>
            )}
            {!isLoading && !error && classes.length > 0 && (
                <div className={"light-grey"}>
                    <View
                        maxWidth={"90%"}
                        margin={"0 auto"}
                        backgroundColor={"#f7f7f7"}
                        paddingTop={"1.5rem"}
                    >
                        {classes.map((cls, idx) => (
                            <div key={idx}>
                                <h2>Class: {cls.name}</h2>
                                <span style={{fontStyle: 'italic'}}>* Click on a URL to copy it to your clipboard *</span>
                                <Grid
                                    templateRows={calculateGridRows(cls.labs)}
                                    templateColumns={"1fr"}
                                    gap={"1.5rem"}
                                >
                                    {cls.labs.map((lab, labIdx) => (

                                        <Card key={labIdx} variation={"elevated"}
                                              onClick={() => copyToClipboard(lab.url)}
                                        className={"hoverable-card"}
                                        >
                                            <Heading className={"lab-heading"} textAlign={"left"}>{lab.name}</Heading>
                                            <Card variation={"outlined"}
                                                textAlign={"center"}
                                                  className={"outlined-card"}
                                                       id={lab.name} >
                                                {lab.url}
                                                </Card>
                                        </Card>
                                    ))}
                                </Grid>
                                <Card>
                                    <span style={{fontStyle: 'italic'}}>* Click on a URL to copy it to your clipboard *</span>
                                </Card>
                            </div>
                        ))}
                    </View>
                </div>
            )}
        </div>
    );
};

export default LabURLs;
